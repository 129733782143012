import { useTranslation } from '@ubique-innovation/react-translations';
import filter from 'lodash/filter';
import React, { useEffect, useState } from 'react';
import { WeatherImage } from '../types/Gallery';
import * as styles from './FullImage.css';
import CloseBtn from '../images/ic-close.svg';
import InvalidIcon from '../images/ic_invalid-weather.svg';
import InvalidImage from '../images/invalid-image.svg';
import ArrowRight from '../images/ic-arrow right.svg';
import ArrowLeft from '../images/ic-arrow left.svg';

const FullImage = ({
    weatherImage: weatherImages,
    setShowFullImg,
    images,
}: {
    weatherImage: WeatherImage | undefined;
    setShowFullImg: (d: boolean) => void;
    images: WeatherImage[];
}): React.ReactElement => {
    const { t } = useTranslation();
    const currentImg = filter(
        images,
        (e) => e.properties.meldungId === weatherImages?.properties.meldungId,
    );
    const [shownImg, setShownImg] = useState<WeatherImage>(currentImg[0]);
    const [showNextArrow, setShowNextArrow] = useState(true);
    const [showPrevArrow, setShowPrevArrow] = useState(true);

    const getDate = (start: number): string => {
        const startDate = new Date(start);
        const date = `0${startDate.getDate()}`;
        const month = `0${startDate.getMonth()}`;
        const year = startDate.getFullYear();
        const hour = `0${startDate.getHours()}`;
        const minute = `0${startDate.getMinutes()}`;
        return `${hour.slice(-2)}:${minute.slice(-2)} —  ${date.slice(
            -2,
        )}.${month.slice(-2)}.${year}`;
    };

    const getAuspraegung = (weather: string): string => {
        return t(`crowdsourcing_attribute_${weather}`);
    };

    const getNextImg = (): void => {
        let index = images.findIndex(
            (e) => e.properties.meldungId === shownImg.properties.meldungId,
        );
        index += 1;

        setShownImg(images[index]);
    };

    const getPrevImg = (): void => {
        let index = images.findIndex(
            (e) => e.properties.meldungId === shownImg.properties.meldungId,
        );
        index -= 1;

        setShownImg(images[index]);
    };
    useEffect(() => {
        let index = images.findIndex(
            (e) => e.properties.meldungId === shownImg.properties.meldungId,
        );
        if (images.length - 1 === index) {
            setShowNextArrow(false);
        } else {
            setShowNextArrow(true);
        }
        if (0 === index) {
            setShowPrevArrow(false);
        } else {
            setShowPrevArrow(true);
        }
    }, [shownImg, images]);

    return (
        <>
            <div className={styles.fullImgContainer} />

            <div className={styles.imgContentContainer}>
                {showPrevArrow && (
                    <button
                        className={styles.arrow}
                        onClick={() => getPrevImg()}
                    >
                        <img src={ArrowLeft} alt="prev-img" />
                    </button>
                )}
                <button
                    onClick={() => setShowFullImg(false)}
                    className={styles.closeBtn}
                >
                    <img src={CloseBtn} alt="close-btn" />
                </button>
                {shownImg !== undefined && (
                    <>
                        <div className={styles.content}>
                            <div className={styles.imgContainer}>
                                <img
                                    className={styles.weatherImg}
                                    src={shownImg.properties.imageOriginalUrl}
                                    alt=""
                                ></img>
                                {!shownImg.properties.qualityCheckPassed && (
                                    <div className={styles.qualityMsg}>
                                        <div className={styles.invalidIcon}>
                                            {shownImg.properties
                                                .qualityCheckMsg &&
                                            shownImg.properties.qualityCheckMsg.startsWith(
                                                'user temporarily blocked',
                                            ) ? (
                                                <img src={InvalidIcon} alt="" />
                                            ) : (
                                                <img
                                                    src={InvalidImage}
                                                    alt=""
                                                />
                                            )}
                                        </div>
                                        <div>
                                            {shownImg.properties.qualityCheckMsg
                                                ? shownImg.properties
                                                      .qualityCheckMsg
                                                : ''}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={styles.timeText}>
                                {getDate(shownImg.properties.timestamp)}
                            </div>
                            <div className={styles.placeText}>
                                {shownImg.properties.place}
                            </div>
                            <div className={styles.weatherCondition}>
                                {`${
                                    shownImg.properties.category
                                        .charAt(0)
                                        .toUpperCase() +
                                    shownImg.properties.category
                                        .slice(1)
                                        .toLowerCase()
                                }: ${getAuspraegung(
                                    shownImg.properties.auspraegung,
                                )}`}
                            </div>
                        </div>
                        {showNextArrow && (
                            <button
                                className={styles.arrow}
                                onClick={() => getNextImg()}
                            >
                                <img src={ArrowRight} alt="next-img" />
                            </button>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default FullImage;
