import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { TranslationProvider } from '@ubique-innovation/react-translations';

import './index.css';
import { RecoilRoot } from 'recoil';

import translationsDe from './translations/de/translations.json';
import App from './containers/App';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';

const resources = {
    de: translationsDe,
};
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
const authorityUrl = process.env.REACT_APP_ISSUER_URL
    ? process.env.REACT_APP_ISSUER_URL
    : '/issuer';
const clientId = 'meteomeldungen-web';

const authConfig: AuthProviderProps = {
    authority: authorityUrl,
    client_id: clientId,
    redirect_uri: `${window.location.origin}/signin-oidc`,
    response_mode: 'fragment',
    scope: 'openid profile met.crowd.read',
    post_logout_redirect_uri: `${window.location.origin}/`,
};

root.render(
    <React.StrictMode>
        <TranslationProvider resources={resources} defaultLang={'de'}>
            <RecoilRoot>
                <BrowserRouter>
                    <AuthProvider {...authConfig}>
                        <App />
                    </AuthProvider>
                </BrowserRouter>
            </RecoilRoot>
        </TranslationProvider>
    </React.StrictMode>,
);
