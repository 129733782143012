import React, { useEffect } from 'react';
import * as styles from './Gallery.css';
import { CategoryCounts, Weather, WeatherImage } from '../types/Gallery';
import Masonry from 'react-masonry-css';
import { useTranslation } from '@ubique-innovation/react-translations';
import { useState } from 'react';
import FullImage from './FullImage';
import InvalidIcon from '../images/ic_invalid-weather.svg';
import InvalidImage from '../images/invalid-image.svg';

const Gallery = ({
    catCount,

    categories,
    weatherImages,
}: {
    catCount: CategoryCounts;
    categories: string[];
    weatherImages: WeatherImage[];
}): React.ReactElement => {
    const [showFullImg, setShowFullImg] = useState(false);
    const [fullImg, setFullImg] = useState<WeatherImage>();
    const [showCount, setShowCount] = useState(true);
    const { t } = useTranslation();

    const getDate = (start: number): string => {
        const startDate = new Date(start);
        const date = `0${startDate.getDate()}`;
        const month = `0${startDate.getMonth() + 1}`;
        const year = startDate.getFullYear();
        const hour = `0${startDate.getHours()}`;
        const minute = `0${startDate.getMinutes()}`;
        return `${hour.slice(-2)}:${minute.slice(-2)} —  ${date.slice(
            -2,
        )}.${month.slice(-2)}.${year}`;
    };

    const breakpointColumnsObj = {
        default: 5,
        1100: 3,
        900: 2,
    };

    const getAuspraegung = (weather: string): string => {
        return t(`crowdsourcing_attribute_${weather}`);
    };

    const control = (): void => {
        let main = document.querySelector('#main');
        if (main) {
            if (main?.scrollTop > 100) {
                setShowCount(false);
            } else {
                setShowCount(true);
            }
        }
        if (showCount) {
            setTimeout(function () {
                setShowCount(false);
            }, 3000);
        }
    };

    useEffect(() => {
        let main = document.querySelector('#main');
        if (main) {
            main.addEventListener('scroll', () => {
                control();
            });
        }
        let sidebar = document.querySelector('#sidebar');
        if (sidebar) {
            sidebar.addEventListener('click', () => {
                control();
            });
        }
    });

    useEffect(() => {
        if (showFullImg) {
            setShowCount(false);
        }
    }, [showFullImg]);

    const getImgCount = (): number => {
        let count = 0;
        if (categories)
            categories.forEach((e) => {
                (Object.keys(Weather) as Array<keyof typeof Weather>).forEach(
                    (key) => {
                        if (e === key) {
                            count += catCount[key].withImg;
                        }
                    },
                );
            });
        return count;
    };

    return (
        <>
            {showFullImg && (
                <FullImage
                    weatherImage={fullImg}
                    setShowFullImg={setShowFullImg}
                    images={weatherImages}
                />
            )}
            {showCount && (
                <div className={styles.imgInfo}>
                    {getImgCount()} Meldungen mit Bild
                </div>
            )}
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className={styles.myMasonryGrid}
                columnClassName={styles.masonryColumn}
            >
                {weatherImages?.map((e) => (
                    <div
                        onClick={() => {
                            setShowFullImg(true);
                            setFullImg(e);
                        }}
                        key={e.properties.meldungId}
                        className={styles.cardView}
                    >
                        <img
                            className={styles.imgResponsive}
                            alt=""
                            src={e.properties.imageThumbnailUrl}
                        />
                        {e.properties.qualityCheckPassed ? (
                            <div className={styles.timeText}>
                                {getDate(e.properties.timestamp)}
                            </div>
                        ) : (
                            <div className={styles.qualityMsg}>
                                {e.properties.qualityCheckMsg &&
                                e.properties.qualityCheckMsg.startsWith(
                                    'user temporarily blocked',
                                ) ? (
                                    <div className={styles.invalidIcon}>
                                        <img src={InvalidIcon} alt="" />
                                    </div>
                                ) : (
                                    <div className={styles.invalidIcon}>
                                        <img src={InvalidImage} alt="" />
                                    </div>
                                )}
                                <div className={styles.invalidMsg}>
                                    {e.properties.qualityCheckMsg}
                                </div>
                            </div>
                        )}
                        <div className={styles.placeText}>
                            {e.properties.place}
                        </div>
                        <div className={styles.weatherCondition}>
                            {`${
                                e.properties.category.charAt(0).toUpperCase() +
                                e.properties.category.slice(1).toLowerCase()
                            }: ${getAuspraegung(e.properties.auspraegung)}`}
                        </div>
                    </div>
                ))}
            </Masonry>
        </>
    );
};

export default Gallery;
