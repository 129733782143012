import React from 'react';
import * as styles from './ValidCheckbox.css';

let className = '';

const getRightClass = (label: string, checked: boolean): string => {
    if (checked) {
        switch (label) {
            case 'Alle':
                className = styles.btnContainer.default;
                break;
            case 'Gültig':
                className = styles.btnContainer.valid;
                break;

            case 'Ungültig':
                className = styles.btnContainer.invalid;
                break;
        }
    }
    return className;
};

const ValidCheckbox = ({
    label,
    checked,
    onChange,
    totalNumber,
}: {
    label: string;
    checked: boolean;
    onChange: React.ChangeEventHandler;
    totalNumber: number;
}): React.ReactElement => {
    return (
        <>
            <label className={styles.labelValid}>
                <input
                    id={label}
                    className={styles.checkbox}
                    key={label}
                    type="checkbox"
                    onChange={onChange}
                    checked={checked}
                />
                <div className={getRightClass(label, checked)}>
                    <div className={styles.optionTitle}>
                        <span>{label}</span>
                        <div className={styles.totalNumber}>{totalNumber}</div>
                    </div>
                </div>
            </label>
        </>
    );
};

export default ValidCheckbox;
