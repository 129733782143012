import React, { useCallback, useEffect, useState } from 'react';
import {
    Categories,
    CategoryCounts,
    PickedDate,
    QualityCounts,
    Validity,
    WeatherImage,
} from '../types/Gallery';
import * as styles from './App.css';
import Main from './Main';
import Sidebar from './Sidebar';
import { themeClass } from './theme.css';
import { useAuth } from 'react-oidc-context';

const API_URL = process.env.REACT_APP_API_URL;

const App = (): React.ReactElement => {
    const [categories, setCategories] = useState<string[]>(Categories);
    const auth = useAuth();

    const [catCount, setCatCount] = useState<CategoryCounts>();
    const [qualityCount, setQualityCount] = useState<QualityCounts>();
    const [validity, setValidity] = useState(Validity.ALL);
    const [images, setImages] = useState<WeatherImage[]>();
    const [showSidebar, setShowSidebar] = useState(true);
    const [date, setDate] = useState<PickedDate>({
        from: new Date().setDate(new Date().getDate() - 1),
        to: new Date().getTime(),
    });

    if (!auth.isAuthenticated && !auth.isLoading) {
        auth.signinRedirect();
    }
    const getCategories = useCallback(() => {
        return categories.join(',');
    }, [categories]);

    useEffect(() => {
        if (!auth.isAuthenticated) {
            return;
        }
        const urlSearchParams = new URLSearchParams({
            from: `${date.from}`,
            to: `${date.to}`,
            categories: getCategories(),
        });
        if (typeof validity === 'string') {
            urlSearchParams.append('quality', validity);
        }
        fetch(`${API_URL}/api/web/v1/meldungen?` + urlSearchParams, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                if (response.status === 403 || response.status === 401 || response.status === 404) {
                    auth.signoutRedirect();
                }
                return Promise.reject();
            })

            .then((data) => {
                setCatCount(data.categoryCounts);
                setQualityCount(data.qualityCounts);
                setImages(data.meldungen);
            })
            .catch(() => {
                console.error(
                    "couldn't fetch record",
                );
                auth.signinRedirect();
            });
    }, [categories, date, validity, getCategories, auth]);

    const handleResize = (): void => {
        if (window.innerWidth < 768) {
            setShowSidebar(false);
        } else {
            setShowSidebar(true);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    });

    return (
        <div className={`${styles.app} ${themeClass}`}>
            {images !== undefined &&
                catCount !== undefined &&
                qualityCount !== undefined &&
                auth.isAuthenticated && (
                    <>
                        {showSidebar && (
                            <Sidebar
                                catCount={catCount}
                                qualityCount={qualityCount}
                                categories={categories}
                                setCategories={setCategories}
                                weatherImages={images}
                                validity={validity}
                                setValidity={setValidity}
                                setShowSidebar={setShowSidebar}
                                date={date}
                                setDate={setDate}
                            />
                        )}
                        <Main
                            catCount={catCount}
                            categories={categories}
                            weatherImages={images}
                            showSidebar={showSidebar}
                            setShowSidebar={setShowSidebar}
                        />
                    </>
                )}
        </div>
    );
};

export default App;
