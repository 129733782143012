export type amount = {
    total: number;
    withImg: number;
};

export interface WeatherImage {
    //   type: "Feature";

    properties: {
        meldungId: 212271;
        // crowdDeviceId: "cb1a7e0f-845d-43b6-93e1-dc53ccc5875d";
        place: string;
        timestamp: number;
        // submissionTimestamp: number;
        category: string;
        auspraegung: string;
        // zusatzAttribute: [];
        // comment: "";
        imageOriginalUrl: string;
        imageUrl: string;
        imageThumbnailUrl: string;
        // numberOfSubmissions: 1;
        approvalRate: number;
        qualityCheckPassed: true;
        // missingValidationData: false;
        qualityCheckMsg: string;
        // timesReportedForImage: 0;
        // timesReportedForWeather: 0;
        // userType: "NORMAL";
        // sourceVersion: "android-2.15";
        // source: "MeteoSwissApp";
        // imageLabels: null;
    };
    geometry: {
        type: 'Point';
        coordinates: [7.015, 46.865];
    };
}

export enum Weather {
    BEWOELKUNG = 'BEWOELKUNG',
    REGEN = 'REGEN',
    HAGEL = 'HAGEL',
    SCHNEEDECKE = 'SCHNEEDECKE',
    SCHNEEFALL = 'SCHNEEFALL',
    GLAETTE = 'GLAETTE',
    BLITZE = 'BLITZE',
    TORNADO = 'TORNADO',
    WIND = 'WIND',
    NEBEL = 'NEBEL',
}

export type PickedDate = {
    from: number;
    to: number;
};

export enum TimeFrame {
    THREEHOURS = 'Letzte 3h',
    DAY = 'Letzte 24h',
    WEEK = 'Letzte 7 Tage',
    MONTH = 'Letzte 30 Tage',
}

export interface WeatherImage {
    meldungen: {
        meldungId: 212271;
        // crowdDeviceId: "cb1a7e0f-845d-43b6-93e1-dc53ccc5875d";
        place: string;
        timestamp: number;
        // submissionTimestamp: number;
        category: string;
        auspraegung: string;
        // zusatzAttribute: [];
        // comment: "";
        imageOriginalUrl: string;
        // imageUrl: string;
        // imageThumbnailUrl: string;
        // numberOfSubmissions: 1;
        approvalRate: number;
        qualityCheckPassed: true;
        // missingValidationData: false;
        qualityCheckMsg: null;
        // timesReportedForImage: 0;
        // timesReportedForWeather: 0;
        // userType: "NORMAL";
        // sourceVersion: "android-2.15";
        // source: "MeteoSwissApp";
        // imageLabels: null;
    };
    geometry: {
        type: 'Point';
        coordinates: [7.015, 46.865];
    };
}

export interface CategoryCounts {
    ALLE: amount;
    BEWOELKUNG: amount;
    BLITZE: amount;
    GLAETTE: amount;
    HAGEL: amount;
    NEBEL: amount;
    REGEN: amount;
    SCHNEEDECKE: amount;
    SCHNEEFALL: amount;
    TORNADO: amount;
    WIND: amount;
}

export interface QualityCounts {
    total: number;
    valid: number;
    invalid: number;
}

export var Categories = [
    'BEWOELKUNG',
    'REGEN',
    'HAGEL',
    'SCHNEEDECKE',
    'GLAETTE',
    'SCHNEEFALL',
    'BLITZE',
    'TORNADO',
    'WIND',
    'NEBEL',
];

export enum Validity {
    ALL,
    VALID = 'VALID',
    INVALID = 'INVALID',
}
