import React from 'react';
import { useTranslation } from '@ubique-innovation/react-translations';
import CategoryCheckbox from '../components/CategoryCheckbox';
import * as styles from './Sidebar.css';
import ValidCheckbox from '../components/ValidCheckbox';
import {
    Categories,
    CategoryCounts,
    PickedDate,
    QualityCounts,
    Validity,
    Weather,
    WeatherImage,
} from '../types/Gallery';
import { filter, includes } from 'lodash';
import CloseBtn from '../images/ic-close.svg';
import DatePicker from '../components/DatePicker';

const Sidebar = ({
    categories,
    setCategories,
    catCount,
    qualityCount,
    weatherImages,
    validity,
    setValidity,
    setShowSidebar,
    date,
    setDate,
}: {
    categories: string[];
    catCount: CategoryCounts;
    qualityCount: QualityCounts;
    setCategories: (d: string[]) => void;
    weatherImages: WeatherImage[];
    validity: Validity;
    setValidity: (d: Validity) => void;
    setShowSidebar: (d: boolean) => void;
    date: PickedDate;
    setDate: (d: PickedDate) => void;
}): React.ReactElement => {
    const { t } = useTranslation();

    const filterCategories = (
        e: React.ChangeEvent<HTMLInputElement>,
        weather: string,
    ): void => {
        if (e.target.checked) {
            const e = [...categories];
            e.push(weather);
            setCategories(e);
        } else {
            const e = [...categories];
            const f = filter(e, (e) => e !== weather);
            setCategories(f);
        }
    };

    return (
        <div className={styles.sidebar} id="sidebar">
            <div className={styles.sidebarHeader}>
                <div>
                    <h1 className={styles.meteoTitle}>{t('app.title')}</h1>
                </div>
                <button
                    onClick={() => setShowSidebar(false)}
                    className={styles.closeBtn}
                >
                    <img src={CloseBtn} alt="close-btn" />
                </button>
            </div>
            <DatePicker date={date} setDate={setDate} />
            <h2>Filter</h2>
            <h3 className={styles.title}>Gültigkeit</h3>
            <p className={styles.description}>
                Alle Meldungen, mit und ohne Bild
            </p>
            <div className={styles.validContainer}>
                <ValidCheckbox
                    label="Alle"
                    checked={validity === Validity.ALL}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.checked) {
                            setValidity(Validity.ALL);
                        }
                    }}
                    totalNumber={qualityCount.total}
                />
                <ValidCheckbox
                    label="Gültig"
                    checked={validity === Validity.VALID}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.checked) {
                            setValidity(Validity.VALID);
                        }
                    }}
                    totalNumber={qualityCount.valid}
                />
                <ValidCheckbox
                    label="Ungültig"
                    checked={validity === Validity.INVALID}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.checked) {
                            setValidity(Validity.INVALID);
                        }
                    }}
                    totalNumber={qualityCount.invalid}
                />
            </div>
            <div className={styles.divider}></div>
            <h3 className={styles.title}>Kategorien</h3>
            <div className={styles.description}>
                <div className={styles.number}>2/4</div> → 2 von 4 Meldungen
                haben ein Bild
            </div>

            <CategoryCheckbox
                icon=""
                label="Alle "
                checked={categories === Categories}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.checked) {
                        setCategories(Categories);
                    } else {
                        setCategories([]);
                    }
                }}
                imageNumber={`${catCount.ALLE.withImg}/${catCount.ALLE.total}`}
            />
            <CategoryCheckbox
                icon="rain"
                label="Regen"
                checked={includes(categories, Weather.REGEN)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    filterCategories(e, Weather.REGEN);
                }}
                imageNumber={`${catCount.REGEN.withImg}/${catCount.REGEN.total}`}
            />
            <CategoryCheckbox
                icon="cloudiness"
                label="Bewölkung"
                checked={includes(categories, Weather.BEWOELKUNG)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    filterCategories(e, Weather.BEWOELKUNG);
                }}
                imageNumber={`${catCount.BEWOELKUNG.withImg}/${catCount.BEWOELKUNG.total}`}
            />
            <CategoryCheckbox
                icon="wind"
                label="Wind"
                checked={includes(categories, Weather.WIND)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    filterCategories(e, Weather.WIND);
                }}
                imageNumber={`${catCount.WIND.withImg}/${catCount.WIND.total}`}
            />
            <CategoryCheckbox
                icon="hail"
                label="Hagel"
                checked={includes(categories, Weather.HAGEL)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    filterCategories(e, Weather.HAGEL);
                }}
                imageNumber={`${catCount.HAGEL.withImg}/${catCount.HAGEL.total}`}
            />
            <CategoryCheckbox
                icon="lightning"
                label="Blitze"
                checked={includes(categories, Weather.BLITZE)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    filterCategories(e, Weather.BLITZE);
                }}
                imageNumber={`${catCount.BLITZE.withImg}/${catCount.BLITZE.total}`}
            />
            <CategoryCheckbox
                icon="hail"
                label="Nebel"
                checked={includes(categories, Weather.NEBEL)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    filterCategories(e, Weather.NEBEL);
                }}
                imageNumber={`${catCount.NEBEL.withImg}/${catCount.NEBEL.total}`}
            />
            <CategoryCheckbox
                icon="snow cover"
                label="Schneefall"
                checked={includes(categories, Weather.SCHNEEFALL)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    filterCategories(e, Weather.SCHNEEFALL);
                }}
                imageNumber={`${catCount.SCHNEEFALL.withImg}/${catCount.SCHNEEFALL.total}`}
            />
            <CategoryCheckbox
                icon="snow"
                label="Schneedecke"
                checked={includes(categories, Weather.SCHNEEDECKE)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    filterCategories(e, Weather.SCHNEEDECKE);
                }}
                imageNumber={`${catCount.SCHNEEDECKE.withImg}/${catCount.SCHNEEDECKE.total}`}
            />
            <CategoryCheckbox
                icon="icy conditions"
                label="Glätte"
                checked={includes(categories, Weather.GLAETTE)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    filterCategories(e, Weather.GLAETTE);
                }}
                imageNumber={`${catCount.GLAETTE.withImg}/${catCount.GLAETTE.total}`}
            />
            <CategoryCheckbox
                icon="tornado"
                label="Tornado"
                checked={includes(categories, Weather.TORNADO)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    filterCategories(e, Weather.TORNADO);
                }}
                imageNumber={`${catCount.TORNADO.withImg}/${catCount.TORNADO.total}`}
            />
        </div>
    );
};

export default Sidebar;
